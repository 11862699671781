.ant-menu-inline, .ant-menu-inline-collapsed{
  margin-top: 5px !important;
}


.ant-menu-item{
  background-color: none;
  color: #212121;
  height: 42px !important;
  transition: all 0.3s;
}

.ant-menu-item a{
  color: #212121 !important;
}

.ant-menu-item.ant-menu-item-selected.menuselected {
  background-color: var(--primaryColor);
  color: white;
  box-shadow: 0 10px 5px -8px rgba(0,0,0,0.4);
}

.ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline{
  border: none;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
  border: var(--primaryColor);
}

.ant-menu-item-selected a{
  color: white !important;
}

.ant-menu-item-selected span{
  font-weight: 600;
}

