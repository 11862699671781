/* hr{
    width: 200px;
    opacity: 0.5;
    margin-top: 30px;
} */

.side-menu .ant-menu-item {

    color: black; /* Color del texto para ítems no seleccionados */
    font-weight: bold;
    margin-top: 15px; /* Espaciado entre ítems */
  }

  .side-menu .ant-menu-item-icon{
    font-size:36px ;
  }

  .side-menu .ant-menu-item-selected a{
  color:var(--primaryColor) !important; /* Color del texto para ítems seleccionados */
  background-color: transparent !important; /* Fondo transparente para ítems seleccionados */
 
}

.side-menu .ant-menu-item-selected .ant-menu-item-icon {
    color: white; /* Color del ícono para ítems seleccionados */
    background-color: var(--primaryColor); /* Fondo rojo para íconos de ítems seleccionados */
    border-radius: 20%; /* Bordes redondeados para el ícono */
    width: 40px; /* Ancho del fondo del ícono */
    height: 40px; /* Altura del fondo del ícono */
    display: flex;
    justify-content: center;
    padding: 4px;
    /*align-items: center;*/
    /*font-size:30px;*/
    
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.ant-menu-item.ant-menu-item-active{
  color: black !important;
}

.ant-menu-inline-collapsed .ant-menu-item .ant-menu-item-icon{
  font-size: 20px ;
}


.title-container {
    display: flex;
    padding-left: 10px;
    padding-top: 11px;
    padding-bottom: 11px;
    
  }

  .title-text {
    color: black;
    font-weight: bold;
    font-size: 1.0rem;
    /*padding-left: 0px;*/
    /*padding-right: 1px;*/
  }
