.ant-btn-background-ghost.ant-btn-primary{
    border-color: transparent;
    color: black;
    width: 150px;
}

.ant-btn-background-ghost.ant-btn-primary:hover, .ant-btn-background-ghost.ant-btn-primary:focus{
    border-color: var(--primaryColor);
    color: var(--primaryColor);
    width: 150px;
}

.children-inner-col-button.ant-btn-primary{
    box-shadow: none;
}

.children-inner-col-button{
    width: 180px;
    height: 180px;
    border-radius: 30px;
}

.children-inner-col-box{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.children-inner-col-icon{
    font-size: 30px;
    margin-left: Calc(50% - 30px);
    padding-top: 15px;
    background-color: var(--primaryColor);
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    padding-left: 14px;
    padding-bottom: 15px;
}

.children-inner-col-label p{
    text-align: center;
    width: 100%;
    max-width: 180px;
    font-weight: 600;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: -10px;
}

@media screen and (max-width: 1200px) {
    .children-inner-col-button{
        width: 135px;
        height: 150px;
        border-radius: 30px;
        
    }

    .children-inner-col-label p{
        text-align: center;
        width: 100%;
        /* max-width: 135px; */
        font-weight: 400;
        font-size: 12px;
        margin-top: 12px;
        /* margin-bottom: -10px; */
    }

    .children-inner-col-icon{
        font-size: 25px;
        margin-left: Calc(50% - 25px);
        padding-top: 13px;
        
        background-color: var(--primaryColor);
        color: white;
        width: 50px;
        height: 50px;
        border-radius: 30px;
    }
}

.container-div{
    padding: 25px 25px;
    /* display: flex; */
    /* justify-content: space-between; */
}

/* .ant-col{
    position: relative;
    max-width: 98% !important;
    min-height: 1px;
} */

/* .ant-card{
    border-radius: 20px !important;
} */

.children-inner-container-with-bg-col{
    transition: width 0.3s ease-in-out;
    width: 100%; 
    height: calc(100vh - 150px) ;   
    box-shadow: none;
    /* overflow-y: auto; */
}

.children-table-global .ant-table{
    width: calc(100vw - 625px) ;
    height: calc(100vh - 333px);
    font-family: 'DM Sans' !important;
    font-size: 14px;
    text-align: center !important;
    scroll-behavior: auto;
    overflow-x: scroll;
    border-radius: 4px;
  }

  .children-table-user .ant-table{
    width: calc(100vw - 605px) ;
    height: calc(100vh - 333px);
    font-family: 'DM Sans' !important;
    font-size: 14px;
    text-align: center !important;
    scroll-behavior: auto;
    overflow-x: scroll;
    border-radius: 4px;
  }

  .children-table-releaseOrders .ant-table{
    width: calc(100vw - 605px) ;
    height: calc(100vh - 220px);
    font-family: 'DM Sans' !important;
    font-size: 14px;
    text-align: center !important;
    scroll-behavior: auto;
    overflow-x: scroll;
    border-radius: 4px;
  }

.mapa-google-v2, #mapa-google-map-v2{
    height: calc(100vh - 150px) !important;
    width: 100% !important;
}


.ant-card-body {
    padding: 15px ;
    /* border-radius: 20px; */
}


.card-scrollbar::-webkit-scrollbar{
    margin: 0px;
    width: 0px; 
    /* height: 10px; */
    /* overflow: hidden; */
    /* height: 5px !important;  */
}

.card-scrollbar:hover::-webkit-scrollbar {
    width: 5px; /* Muestra la barra de desplazamiento al hacer hover */
    /* height: 20px; */
}

.card-scrollbar::-webkit-scrollbar-track{
    background-color: white;
    border-radius: 5px;
    margin-top: 100px;
    margin-bottom: 100px;
    
}

.card-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
    /* border: 2px solid #f1f1f1; */
    /* margin-top: 50px;
    margin-bottom: 50px; */
}

.card-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* border: 2px solid #f1f1f1; */
    /* overflow: auto; */
}

.muiCardContent-root:last-child {
padding-bottom: 16px !important;
}

.footer_version{
    color: #000;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
}