
.title-text {
  font-weight: bold !important;
  font-size: 16px !important;
  margin-left: 5px !important;
}

.MuiToolbar-root{
  background-color: var(--primaryColor) ;
}

.css-10hburv-MuiTypography-root{
  margin: 0 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 2.5 !important;
  letter-spacing: 0.00938em !important;
  display: block !important;
}

.css-19z1ozs-MuiPaper-root-MuiAppBar-root{
  width: calc(100% - 64px) !important; 
}


   

 

