:root{
  --primaryColor: var(--primaryColor);
}

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 10px; /* o el ancho que desees */
} 

body::-webkit-scrollbar-track {
  background: #f1f1f1; /* o cualquier color de fondo que prefieras */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.table-row-color {
  background-color: #DCF4FF;
}

.mouse-row-enter {
  cursor: pointer;
}