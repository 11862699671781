.tms-container{
    height: calc(100vh - 68px);
    width: 100%;
}

.tms-inner-container{
    position: relative;
    background-color: white;
    width: 97%;
    height: 94%;
    left: 1.5%;
    top: 3%;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    border-radius: 30px;
    padding: 24px;
}

.tms-loading{
    position: relative;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
}

.tms-filters-container{
    padding-top: 2px;
    width: 100%;
    height: 160px;
    box-shadow: 0px 1px 12px 6px rgba(0,0,0,0.1);
    border-radius: 15px;
}

.tms-round-button{
    position: absolute !important;
    right: 35px;
    top: 35px;
    background-color: var(--primaryColor) !important;
    color: white !important;
}

.tms-round-button:hover{
    opacity: 80%;
}

.tms-title{
    font-size: 24px;
    font-weight: 600;
    margin-left: 30px;
    margin-top: 4px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.tms-search-textfield{
    margin-top: 8px !important;
    margin-left: 30px !important;
    width: calc(33% - 65px);
}

.tms-filters-separator{
    position: absolute !important;
    content: "";
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    width:1px;
    height: 130px;
    top: 40px;
    left: 33%;
}

.tms-filters-separator2{
    position: absolute !important;
    content: "";
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    width:1px;
    height: 130px;
    top: 40px;
    left: 66%;
}

.tms-filters-dispatched-container{
    position: absolute !important;
    width: 33%;
    top: 38px;
    left: calc(33% + 20px);
    display: flex;
    flex-direction: row;
    padding-top: 25px;
    z-index: 10;
}

.tms-filters-dispatched-title{
    position: absolute !important;
    top: 0px;
    font-size: 14px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.tms-filters-dispatched-button-group{
    width: calc(100% - 37px) !important;
}

.tms-filters-dispatched-container2{
    position: absolute !important;
    width: 33%;
    top: 38px;
    left: calc(33% + 20px);
    display: flex;
    flex-direction: row;
    padding-top: 25px;
}

.tms-filters-dispatched-title2{
    position: absolute !important;
    top: 70px;
    font-size: 14px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.tms-filters-dispatched-button2{
    margin-top: 70px !important;
}

.tms-filters-pagination-container{
    position: absolute !important;
    width: 33%;
    top: 120px;
    left: calc(66% + 20px);
    display: flex;
    flex-direction: row;
}

.tms-filters-pagination-button{
    margin-top:  2px !important;
    height: 52px;
    border-radius: 10px !important;
    margin-right: 10px !important;
}

.tms-filters-pagination-count-text{
    margin-top: 20px !important;
    font-size: 46px !important;
    font-weight: bold;
}

.tms-filters-pagination-selector{
    
}